











import Introduction from "@/components/home/Introduction.vue";
import Features from "@/components/home/Features.vue";
import MobileVTT from "@/components/home/MobileVTT.vue";
import Feedback from "@/components/home/Feedback.vue";
import Faq from "@/components/home/Faq.vue";
import Cta from "@/components/home/Cta.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    Introduction,
    Features,
    MobileVTT,
    Feedback,
    Faq,
    Cta
  },
})
export default class Home extends Vue {}
