import { AuthorizationRequest } from "@openid/appauth/built/authorization_request";
import {
    AuthorizationNotifier,
    AuthorizationRequestHandler,
    AuthorizationRequestResponse,
    BUILT_IN_PARAMETERS
} from "@openid/appauth/built/authorization_request_handler";
import { AuthorizationResponse } from "@openid/appauth/built/authorization_response";
import { AuthorizationServiceConfiguration } from "@openid/appauth/built/authorization_service_configuration";
import { NodeCrypto } from '@openid/appauth/built/node_support/';
import { BasicQueryStringUtils, DefaultCrypto, LocalStorageBackend, RedirectRequestHandler } from "@openid/appauth/built";
import { NodeRequestor } from "@openid/appauth/built/node_support/node_requestor";
import {
    GRANT_TYPE_AUTHORIZATION_CODE,
    GRANT_TYPE_REFRESH_TOKEN,
    TokenRequest
} from "@openid/appauth/built/token_request";
import {
    BaseTokenRequestHandler,
    TokenRequestHandler
} from "@openid/appauth/built/token_request_handler";
import {
    TokenError,
    TokenResponse
} from "@openid/appauth/built/token_response";


import { LocationLike, StringMap } from "@openid/appauth/built/types";
import auth from ".";
const requestor = new NodeRequestor();

export class AccessTokenResult {
    accessToken: string
    idToken: string | undefined
    issuedAt: number
    expiresIn: number | undefined
    tokenType: string
    scope: string | undefined
    constructor(accessToken: string, idToken: string | undefined, issuedAt: number, expiresIn: number | undefined, tokenType: string, scope: string | undefined) {
        this.accessToken = accessToken
        this.idToken = idToken
        this.issuedAt = issuedAt
        this.expiresIn = expiresIn
        this.tokenType = tokenType
        this.scope = scope
    }
}

class NoHashQueryStringUtils extends BasicQueryStringUtils {
    parse(input: LocationLike, useHash?: boolean): StringMap {
        return super.parse(input, false /* never use hash */);
    }
}

const clientId = process.env.VUE_APP_CLIENT_ID

const scope = "openid"
export class AuthFlow {
    private notifier: AuthorizationNotifier;
    private authorizationHandler: AuthorizationRequestHandler;
    private tokenHandler: TokenRequestHandler;

    // state
    private configuration: AuthorizationServiceConfiguration | undefined;
    private request: AuthorizationRequest | undefined;
    private response: AuthorizationResponse | undefined;
    private code: string | undefined;
    private refreshToken: string | undefined;
    private accessTokenResponse: TokenResponse | undefined;
    private oauthServerUrl: string
    private redirectUri: string
    constructor() {
        const authUrl = process.env.VUE_APP_OAUTH_URL
        if (authUrl.endsWith('/')) {
            this.oauthServerUrl = authUrl.slice(0, -1)
        } else {
            this.oauthServerUrl = authUrl
        }
        const appUrl = process.env.VUE_APP_URL
        if (appUrl.endsWith('/')) {
            this.redirectUri = `${appUrl}auth/callback`
        } else {
            this.redirectUri = `${appUrl}/auth/callback`
        }

        console.log(`oauth server url: ${this.oauthServerUrl} redirect url: ${this.redirectUri}`)
        // set notifier to deliver responses
        this.notifier = new AuthorizationNotifier()
        this.authorizationHandler = new RedirectRequestHandler(
            new LocalStorageBackend(),
            new NoHashQueryStringUtils(),
            window.location,
            new DefaultCrypto())
        this.authorizationHandler.setAuthorizationNotifier(this.notifier)
        this.tokenHandler = new BaseTokenRequestHandler(requestor)

        // set a listener to listen for authorization responses
        // make refresh and access token requests.
        this.notifier.setAuthorizationListener((request, response, error) => {
            if (response instanceof TokenResponse) {
                this.accessTokenResponse = response
            }
            if (response) {
                this.request = request;
                this.response = response;
                this.code = response.code;
            }
        });
    }

    fetchServiceConfiguration(): Promise<void> {
        return AuthorizationServiceConfiguration.fetchFromIssuer(
            this.oauthServerUrl,
            requestor
        ).then(response => {
            this.configuration = response;
        });
    }

    makeAuthorizationRequest(username?: string) {
        if (!this.configuration) {
            return;
        }
        // eslint-disable-next-line
        const extras: StringMap = { prompt: "consent", access_type: "offline" };
        if (username) {
            extras["login_hint"] = username;
        }

        // create a request
        /* eslint-disable */
        console.log(`Redirect uri: ${this.redirectUri} scope: ${scope}`)
        const request = new AuthorizationRequest({
            client_id: clientId,
            redirect_uri: this.redirectUri,
            scope: scope,
            response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
            state: undefined,
            extras: extras
        }, new NodeCrypto());
        /* eslint-enable */

        this.authorizationHandler.performAuthorizationRequest(
            this.configuration,
            request
        );
    }

    makeTokenRequest(): Promise<AccessTokenResult | undefined> {
        const configuration = this.configuration
        if (!configuration) {
            return new Promise((resolve, reject) => {
                resolve(undefined)
            })
        }
        return this.authorizationHandler.completeAuthorizationRequestIfPossible().then(() => {
            if (!this.code) {
                return undefined
            }
            let extras: StringMap | undefined = undefined
            if (this.request && this.request.internal) {
                extras = {}
                extras['code_verifier'] = this.request.internal['code_verifier']
            }
            /* eslint-disable */
            const request = new TokenRequest({
                client_id: clientId,
                redirect_uri: this.redirectUri,
                grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
                code: this.code,
                refresh_token: undefined,
                extras: extras
            });
            /* eslint-enable */

            return this.tokenHandler.performTokenRequest(configuration, request)
                .then(response => {
                    const accessToken = response.accessToken
                    const issuedAt = response.issuedAt;
                    const expiresIn = response.expiresIn;
                    const tokenType = response.tokenType;
                    const scope = response.scope;
                    return new AccessTokenResult(
                        accessToken,
                        response.idToken,
                        issuedAt,
                        expiresIn,
                        tokenType,
                        scope,
                    )
                }).catch(err => {
                    return undefined
                });
        })
    }

    private makeRefreshTokenRequest(code: string, codeVerifier: string | undefined): Promise<void> {
        if (!this.configuration) {
            return Promise.resolve();
        }

        const extras: StringMap = {};
        /* eslint-disable */
        if (codeVerifier) {
            extras.code_verifier = codeVerifier;
        }

        // use the code to make the token request.
        let request = new TokenRequest({
            client_id: clientId,
            redirect_uri: this.redirectUri,
            grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
            code: code,
            refresh_token: undefined,
            extras: extras
        });
        /* eslint-ensable */

        return this.tokenHandler
            .performTokenRequest(this.configuration, request)
            .then(response => {
                this.refreshToken = response.refreshToken;
                this.accessTokenResponse = response;
                return response;
            })
            .then(() => { });
    }

    loggedIn(): boolean {
        return !!this.accessTokenResponse && this.accessTokenResponse.isValid();
    }

    signOut() {
        // forget all cached token state
        this.accessTokenResponse = undefined;
    }

    performWithFreshTokens(): Promise<string> {
        if (!this.configuration) {
            return Promise.reject("Unknown service configuration");
        }
        if (!this.refreshToken) {
            return Promise.resolve("Missing refreshToken.");
        }
        if (this.accessTokenResponse && this.accessTokenResponse.isValid()) {
            // do nothing
            return Promise.resolve(this.accessTokenResponse.accessToken);
        }
        let request = new TokenRequest({
            client_id: clientId,
            redirect_uri: this.redirectUri,
            grant_type: GRANT_TYPE_REFRESH_TOKEN,
            code: undefined,
            refresh_token: this.refreshToken,
            extras: undefined
        });

        return this.tokenHandler
            .performTokenRequest(this.configuration, request)
            .then(response => {
                this.accessTokenResponse = response;
                return response.accessToken;
            });
    }
}