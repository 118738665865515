class Account {
    public accountId: number
    public accountName: string
    public firstName: string | undefined
    public nickName: string | undefined
    public surName: string | undefined
    public accessToken: string | undefined
    public idToken: string | undefined
    public proFeatures: boolean

    constructor(accountId: number,
        accountName: string,
        firstName: string | undefined,
        nickName: string | undefined,
        surName: string | undefined,
        accessToken: string | undefined,
        idToken: string | undefined,
        proFeatures: boolean) {

        this.accountId = accountId
        this.accountName = accountName
        this.firstName = firstName
        this.nickName = nickName
        this.surName = surName
        this.accessToken = accessToken
        this.idToken = idToken
        this.proFeatures = proFeatures
    }
}
export default Account