import axios from "axios";

export interface SubmissionRequest {
    signUpIos: boolean;
    signUpAndroid: boolean;
    googlePlayEmail?: string | null;
}

export interface SubmissionResponse {
    success: boolean;
    errorMessage: string | null;
    signedUpIos: boolean;
    signedUpAndroid: boolean;
}

export default class SignUpApiService {
    accessToken: string;
    idToken: string;
    baseUrl: string;

    constructor(accessToken: string, idToken: string) {
        const apiUrl = process.env.VUE_APP_API_BASE_URL;
        if (apiUrl.endsWith('/')) {
            this.baseUrl = apiUrl
        } else {
            this.baseUrl = apiUrl + '/'
        }
        this.accessToken = accessToken
        this.idToken = idToken
    }

    postSubmission(submission: SubmissionRequest): Promise<SubmissionResponse> {
        const req = {
            signUpIos: submission.signUpIos,
            signUpAndroid: submission.signUpAndroid,
            googlePlayEmail: submission.googlePlayEmail
        }

        const headers = {
            "Authorization": `Bearer ${this.accessToken}`,
            "X-Token-ID": this.idToken
        }

        return axios.post(`${this.baseUrl}request/submit`, req, { headers })
            .then(_response => {
                return { success: true, errorMessage: null, signedUpIos: req.signUpIos, signedUpAndroid: req.signUpAndroid };
            }).catch(error => {
                let msg: string;
                if (error.message) {
                    msg = error.message
                } else {
                    msg = "Something went wrong."
                }
                return { success: false, errorMessage: msg, signedUpIos: false, signedUpAndroid: false }
            });
    }
}