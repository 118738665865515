import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import SignUpApiService from '@/services/signup-api-service'

//See https://championswimmer.in/vuex-module-decorators/ for an explanation of the syntax used here.
@Module({ namespaced: true, name: 'submission' })
export default class SubmissionState extends VuexModule {
    successful: boolean | null = null;
    errorMessage: string | null = null;
    signedUpIos: boolean | null = null;
    signedUpAndroid: boolean | null = null;

    @Mutation
    clear() {
        this.successful = null
        this.errorMessage = null
        this.signedUpAndroid = null
        this.signedUpIos = null
    }

    @Mutation
    setSuccessful(value: {
        success: boolean;
        errorMessage?: string;
        signedUpIos: boolean;
        signedUpAndroid: boolean;
    }) {
        console.log(`Setting success values successful: ${value.success} errorMessage: ${value.errorMessage}`)
        this.successful = value.success;
        if (value.success) {
            this.signedUpIos = value.signedUpIos
            this.signedUpAndroid = value.signedUpAndroid
            this.errorMessage = null
        } else {
            this.signedUpIos = false
            this.signedUpAndroid = false
            this.errorMessage = value.errorMessage ? value.errorMessage : null;
        }
    }

    @Action({ rawError: true })
    submitRequest(value: {
        vue: Vue;
        signUpIos: boolean;
        signUpAndroid: boolean;
        googlePlayEmail?: string | null;
    }): Promise<boolean> {
        const accessToken: string = value.vue.$auth.accessToken
        const idToken: string = value.vue.$auth.token
        const svc = new SignUpApiService(accessToken, idToken)
        console.log("Submitting the request")
        return svc.postSubmission(value).then(response => {
            this.context.commit('setSuccessful', response)
            return response.success
        })
    }
}