














































import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import account from "@/store/modules/account";

@Component
export default class Introduction extends Vue {
  accountModule!: account;

  get loggedIn(): boolean {
    return this.accountModule.isLoggedIn;
  }

  created() {
    this.accountModule = getModule(account, this.$store);
  }
}
