import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue)

import VueRellax from 'vue-rellax'
Vue.use(VueRellax)

import auth from '@/plugins/auth'
Vue.use(auth)

import VueScrollTo from 'vue-scrollto'
Vue.use(VueScrollTo)

Vue.config.productionTip = false

const vue = new Vue({
  router,
  render: h => h(App),
  store,
}).$mount('#app')