import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import SignUp from '@/views/SignUp.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/auth/callback',
    name: 'Callback',
    component: () => import(/* webpackChunkName: "callback" */ '../views/Callback.vue')
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () => import(/* webpackChunkName: "signup" */ '../views/SignUp.vue')
  },
  {
    path: '/signup/success',
    name: 'Success',
    component: () => import(/* webpackChunkName: "success" */ '../views/Successful.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/logout',
    name: 'Logout',
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

import { getModule } from "vuex-module-decorators";
import accountStore from "@/store/modules/account";

const openRoutes = ['Home', 'Callback']

// very basic "setup" of a global guard
router.beforeEach((to, from, next) => {
  if (!router.app.$auth) {
    console.log("$auth not found on app!")
  }
  if (openRoutes.includes(to.name ?? "Unknown")) {
    next()
    return
  }
  if (to.name == "Logout") {
    router.app.$auth.logout()
    const accountModule = getModule(accountStore, router.app.$store);
    accountModule.update(undefined)
    next('/')
  } else if (router.app.$auth.isAuthenticated()) { // if authenticated allow access
    next()
  } else {
    router.app.$auth.login()
  }
})

export default router
