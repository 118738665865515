
















import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import account from "@/store/modules/account";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";
import store from "@/store";

@Component({
  components: {
    Menu,
    Footer,
  },
})
export default class App extends Vue {
  created() {
    const accountModule = getModule(account, this.$store);
    accountModule.update(this.$auth.account);
  }
}
