import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import Account from '@/plugins/auth/account'
//See https://championswimmer.in/vuex-module-decorators/ for an explanation of the syntax used here.
@Module({ namespaced: true, name: 'account' })
class AccountState extends VuexModule {
  account: Account | undefined
  isLoggedIn = false
  isProUser = false

  @Mutation
  setAccount(value: Account | undefined): void {
    console.log(`Setting account ${value?.accountName}`)
    this.account = value
    this.isLoggedIn = this.account != undefined && this.account != null
    this.isProUser = this.account?.proFeatures ?? false
  }

  @Action({ rawError: true })
  update(value: Account | undefined): void {
    this.context.commit('setAccount', value)
  }

}
export default AccountState