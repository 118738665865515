import Vue from 'vue'
import Vuex from 'vuex'
import account from './modules/account'
import submission from './modules/submission'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        account,
        submission
    }
})
export default store