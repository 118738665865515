import Vue from 'vue'
import { AuthFlow } from './flow'
//eslint-disable-next-line
import jwt_decode from "jwt-decode"
import Account from './account'

// https://auth.roll20staging.net
// id roll20-mobile-beta-signup-local
// name "Roll20 Mobile Beta Signup Local"
// grant-types authorization_code,refresh_token,client_credentials,implicit
// scope openid,offline
// token-endpoint-auth-method none
// valid callbacks localhost:8080://auth/callback localhost:8081://auth/callback
class VueAuth extends Vue {
    get token(): string | null {
        return localStorage.getItem('id_token')
    }
    set token(idToken: string | null) {
        if (idToken) {
            localStorage.setItem('id_token', idToken)
        } else {
            localStorage.removeItem('id_token')
        }
    }

    get accessToken(): string | null {
        return localStorage.getItem('access_token')
    }
    set accessToken(accessToken: string | null) {
        if (accessToken) {
            localStorage.setItem('access_token', accessToken)
        } else {
            localStorage.removeItem('access_token')
        }
    }

    get expiresAt(): Date | null {
        const dt = Number(localStorage.getItem('expires_at'))
        return new Date(dt)
    }
    set expiresAt(expires: Date | null) {
        if (expires) {
            localStorage.setItem('expires_at', expires.getTime().toString())
        } else {
            localStorage.removeItem('expires_at')
        }
    }

    get account(): Account | null {
        if (!this.token) { return null }
        //eslint-disable-next-line
        const jwt = jwt_decode<any>(this.token)
        const accountId = jwt["account_id"] as number | undefined
        const accountName = jwt["sub"] as string | undefined
        if (!accountId || !accountName) { return null }

        return new Account(accountId, accountName,
            jwt["name"] as string,
            jwt["nickname"] as string,
            jwt["surname"] as string,
            this.accessToken ?? undefined,
            this.token,
            jwt["pro_features"] as boolean ?? false)
    }

    private authFlow: AuthFlow = new AuthFlow()

    constructor() {
        super()

    }

    login() {
        console.log("Making an authorization request.")
        this.authFlow.fetchServiceConfiguration().then(() => {
            this.authFlow.makeAuthorizationRequest()
        })
    }

    completeLoginIfPossible(): Promise<Account | null> {
        console.log("Attempting to complete login")
        return new Promise<Account | null>((resolve, reject) => {
            console.log("Making token request")
            this.authFlow.fetchServiceConfiguration().then(() => {
                this.authFlow.makeTokenRequest().then(authResult => {
                    if (authResult && authResult.accessToken && authResult.idToken) {
                        if (authResult.expiresIn) {
                            const now = new Date()
                            this.expiresAt = new Date(now.getTime() + authResult.expiresIn * 1000)
                        }
                        this.accessToken = authResult.accessToken
                        this.token = authResult.idToken
                        resolve(this.account)
                    } else {
                        this.logout()
                        resolve(null)
                    }
                })
            })
        })
    }

    logout() {
        localStorage.removeItem('access_token')
        localStorage.removeItem('id_token')
        localStorage.removeItem('expires_at')
        this.authFlow.signOut()
    }

    isAuthenticated() {
        const expiresAt = this.expiresAt
        if (expiresAt == null) { return false }
        return new Date().getTime() < expiresAt.getTime()
    }
}

const auth = new VueAuth()

export default {
    install: function (Vue: any) {
        Vue.prototype.$auth = auth
    }
}